// import Vue from 'vue'
import { ref, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import useAppConfig from '@core/app-config/useAppConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import language from 'flatpickr/dist/l10n/zh-tw'
import moment from 'moment'

// import Toast from 'vue-toastification'
import '@core/scss/vue/libs/toastification.scss'

export const useCommenSettings = () => {
  const refonlineTime = ref(0)

  const onlineTime = () => {
    refonlineTime.value += 1
  }

  const updateOnline = time => {
    const createTime = Math.floor(new Date(time).getTime() / 1000)
    const today = Math.floor(new Date().getTime() / 1000)
    const diffTime = today - createTime
    if (diffTime < 0) return '時間錯誤'
    // 定義單位時間
    const second = 1
    const minute = second * 60
    const hour = minute * 60

    // 定義換算時間
    let calcTime
    const secondCalc = parseInt(diffTime / second, 10)
    const minuteCalc = parseInt(diffTime / minute, 10)
    const hourCalc = parseInt(diffTime / hour, 10)

    if (hourCalc >= 1 && hourCalc <= 23) {
      calcTime = `${hourCalc} 小時前`
    } else if (minuteCalc >= 1 && minuteCalc <= 59) {
      calcTime = `${minuteCalc} 分鐘前`
    } else if (secondCalc >= 1 && secondCalc <= 59) {
      calcTime = `${secondCalc} 秒前`
    } else {
      calcTime = moment(time).format('YYYY-MM-DD')
    }
    return calcTime
  }

  // (同步)物件
  const syncObject = (obj1, obj2) => {
    const NewData = {
      ...obj1,
    }
    const keys = Object.keys(obj1)
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i]
      if (Object.prototype.hasOwnProperty.call(obj2, key)) {
        if (obj2[key] !== null) NewData[key] = obj2[key]
      }
    }
    return NewData
  }

  // (同步)物件
  const syncObjectDeep = (obj1, obj2) => {
    const newData = { ...obj1 }

    Object.keys(obj1).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(obj2, key)) {
        if (typeof obj1[key] === 'object' && obj1[key] !== null && typeof obj2[key] === 'object' && obj2[key] !== null) {
          newData[key] = syncObjectDeep(obj1[key], obj2[key])
        } else {
          newData[key] = obj2[key] !== null ? obj2[key] : obj1[key]
        }
      }
    })

    return newData
  }

  // (同步)物件
  const syncObjectDeepAsync = async (obj1, obj2) => {
    const sync = (source, target) => {
      const synced = { ...source }
      const keys = Object.keys(source)
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i]
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          if (typeof source[key] === 'object' && source[key] !== null) {
            synced[key] = sync(source[key], target[key] || {})
          } else {
            synced[key] = target[key] !== undefined ? target[key] : source[key]
          }
        }
      }
      return synced
    }

    return sync(obj1, obj2)
  }

  return {
    refonlineTime,
    onlineTime,
    updateOnline,
    syncObject,
    syncObjectDeep,
    syncObjectDeepAsync,
  }
}

export const useTableComponent = () => {
  const refDataListTable = ref(null)
  const perPage = ref(50)
  const totalNum = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [20, 50, 100, 200]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const timeRange = ref(null)
  const tableColumns = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refDataListTable.value ? refDataListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalNum.value,
    }
  })

  const dateDefaluteConfig = {
    locale: language.zh_tw,
    mode: 'range',
    dateFormat: 'Y-m-d',
  }

  const refetchData = () => refDataListTable.value.refresh()

  const resolveRangeTime = time => {
    let resolveTimeRange = null
    resolveTimeRange = time.split(' 至 ')
    const [start, end] = resolveTimeRange
    if (end) {
      resolveTimeRange = `${start} to ${end}`
    } else resolveTimeRange = `${start}`

    return resolveTimeRange
  }

  return {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    timeRange,
    tableColumns,
    dataMeta,
    dateDefaluteConfig,
    refetchData,
    resolveRangeTime,
  }
}

export const useTimerComponent = () => {}

export const useAlert = () => {
  const toast = useToast()

  const useAlertToast = (state, message) => {
    toast({
      component: ToastificationContent,
      props: {
        title: message,
        icon: state ? 'CheckCircleIcon' : 'InfoIcon',
        variant: state ? 'success' : 'danger',
      },
    })
  }

  const useHttpCodeAlert = response => {
    if (!response) {
      useAlertToast(false, '程式碼錯誤')
      return
    }
    switch (response.status) {
      case 200:
        useAlertToast(true, response.data.message)
        break
      case 400:
        useAlertToast(false, response.data.message)
        break
      case 401:
        useAlertToast(false, '權限不足')
        break
      case 404:
        useAlertToast(false, '找不到路徑')
        break
      case 500:
        useAlertToast(false, '系統錯誤')
        break
      default:
        useAlertToast(false, response.data.message)
        break
    }
  }

  // const toast = {
  //   component: ToastificationContent,
  //   props: {
  //     title: message,
  //     icon: state ? 'CheckCircleIcon' : 'InfoIcon',
  //     variant: state ? 'success' : 'danger',
  //   },
  // }

  return {
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useSwalToast = {
  methods: {
    useSwalAlertToast(state, message, position) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: position !== undefined ? position : 'top-end',
        width: 300,
        timer: 1500,
        showConfirmButton: false,
        background: `${this.isDark ? '#344464' : '#fff'}`,
        customClass: {
          title: `modal-title-toast ${this.isDark ? 'modal-title-toast-dark' : null}`,
        },
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__bounceOutRight',
        },
      })

      Toast.fire({
        title: `${message}`,
        icon: state ? 'success' : 'error',
      })
    },

    useSwalAlertCenter(state, title, message) {
      const Toast = this.$swal.mixin({
        showConfirmButton: false,
        background: `${this.isDark ? '#344464' : '#fff'}`,
        customClass: {
          title: `${this.isDark ? 'modal-title-dark' : 'modal-title'}`,
          content: `${this.isDark ? 'modal-content-dark' : 'modal-content'}`,
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
      })

      Toast.fire({
        title: `${title}`,
        text: `${message}`,
        icon: state ? 'success' : 'error',
      })
    },

    useSwalAlertTimer(state, title, message, timer) {
      const Toast = this.$swal.mixin({
        showConfirmButton: false,
        timer: timer !== undefined ? timer : 3000,
        timerProgressBar: true,
        background: `${this.isDark ? '#344464' : '#fff'}`,
        customClass: {
          title: `${this.isDark ? 'modal-title-dark' : 'modal-title'}`,
          content: `${this.isDark ? 'modal-content-dark' : 'modal-content'}`,
          timerProgressBar: 'modal-content-progress-bar',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
      })

      Toast.fire({
        title: `${title}`,
        text: `${message}`,
        icon: state ? 'success' : 'error',
      })
    },

    useSwalAlertWarning(title, message) {
      const Toast = this.$swal.mixin({
        background: `${this.isDark ? '#344464' : '#fff'}`,
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          title: `${this.isDark ? 'modal-title-dark' : 'modal-title'}`,
          content: `${this.isDark ? 'modal-content-dark' : 'modal-content'}`,
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      })

      return Toast.fire({
        title: `${title}`,
        text: `${message}`,
        icon: 'warning',
      })
    },

    useSwalAlertInfo(title, message) {
      const Toast = this.$swal.mixin({
        background: `${this.isDark ? '#344464' : '#fff'}`,
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          title: `${this.isDark ? 'modal-title-dark' : 'modal-title'}`,
          content: `${this.isDark ? 'modal-content-dark' : 'modal-content'}`,
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      })

      return Toast.fire({
        title: `${title}`,
        text: `${message}`,
        icon: 'info',
      })
    },

    useSwalAlertInfomation(title, message) {
      const Toast = this.$swal.mixin({
        background: `${this.isDark ? '#344464' : '#fff'}`,
        padding: '3em 1em',
        showConfirmButton: false,
        // showCloseButton: true,
        customClass: {
          title: `${this.isDark ? 'modal-title-dark' : 'modal-title'}`,
          content: `modal-content-word-break ${this.isDark ? 'modal-content-dark' : 'modal-content'}`,
        },
        showClass: {
          popup: 'animate__animated animate__fadeInDown animate__faster',
        },
        hideClass: {
          popup: 'animate__animated animate__bounceOut animate__faster',
        },
      })

      return Toast.fire({
        title: `${title}`,
        text: `${message}`,
      })
    },

    // // (輔助)：hex轉成 RGB颜色值
    // hexToRgb(hex) {
    //   const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    //   const newhex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
    //   const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newhex)
    //   return result
    //     ? {
    //       r: parseInt(result[1], 16),
    //       g: parseInt(result[2], 16),
    //       b: parseInt(result[3], 16),
    //     }
    //     : null
    // },

    // // (輔助)：RGB轉成hex 颜色值
    // rgbToHex(rgb) {
    //   return `#${(16777216 + (rgb.r * 65536) + (rgb.g * 256) + rgb.b).toString(16).slice(1)}`
    // },

    // // (反轉)色系
    // invertColor(hex) {
    //   // 将 hex 颜色值转为 RGB
    //   const rgb = this.hexToRgb(hex)

    //   // 计算反色的 RGB 值
    //   const invertedRgb = {
    //     r: 255 - rgb.r,
    //     g: 255 - rgb.g,
    //     b: 255 - rgb.b,
    //   }

    //   // 将 RGB 转为 hex 颜色值
    //   return this.rgbToHex(invertedRgb)
    // },
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return {
      isDark,
    }
  },
}
